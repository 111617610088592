import React from 'react';
import styled from 'styled-components/macro';
import Button from 'components/button';
import Play from 'components/icons/play';

type Props = {
  onPlay: () => void;
  onStop: () => void;
  isSlave?: boolean;
  rejected?: boolean;
  isInit?: boolean;
  onInit: () => void;
};

const Component: React.FC<Props> = ({ onInit, onPlay, onStop, ...props }) => {
  if (props.isSlave && props.rejected) {
    return (
      <Container {...props}>
        <div>Sync rejected.</div>
        <div>(over capacity)</div>
      </Container>
    );
  }
  // if (props.isSlave && props.isInit) {
  //   return (
  //     <Container>
  //       <div>Slave mode</div>
  //     </Container>
  //   );
  // }
  return (
    <Container {...props}>
      <ExtButton onTurnOn={props.isSlave ? onInit : onPlay} onTurnOff={onStop} disable={props.isSlave && props.isInit}>
        <ExtPlay />
      </ExtButton>
      {props.isSlave && <div>Slave mode</div>}
    </Container>
  );
};

const Container = styled.div`
  font-family: 'Valera';
  margin: 20px;
`;

const ExtButton = styled(Button)`
  width: 80px;
  height: 80px;
  border-radius: 50%;
`;

const ExtPlay = styled(Play)`
  width: 20px;
  height: 20px;
  margin-left: 6px;
  margin-top: 2px;
`;

export default Component;
