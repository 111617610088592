import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
// import io from 'socket.io-client';
import { createGlobalStyle } from 'styled-components/macro';

import 'sanitize.css';
import DSGL7MM from 'assets/fonts/DSEG7ModernMini-Regular.woff2';
import { base, baseGradient, darkGray } from 'constants/color';
import { neumorphicShadow } from 'constants/mixin';

import Home from 'pages/home';
import Footer from 'components/footer';

import { ModeType } from 'constants/type';
import firebase from 'config/firebase';

const App: React.FC = () => {
  const [mode, setMode] = useState<ModeType>('home');
  const [userId, setUserId] = useState<string>('');
  // const [pushToken, setPushToken] = useState<string>('');
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
      }
    });
  }, []);

  useEffect(() => {
    firebase
      .auth()
      .signInAnonymously()
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  // useEffect(() => {
  //   Notification.requestPermission().then((permission) => {
  //     if (permission === 'granted') {
  //       console.log('granted');
  //     } else {
  //       console.log('notification permission denied');
  //     }
  //   });
  // }, []);
  //
  // useEffect(() => {
  //   firebase
  //     .messaging()
  //     .getToken()
  //     .then((token) => {
  //       if (token) {
  //         setPushToken(token);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);
  //
  // useEffect(() => {
  //   firebase.messaging().onMessage((payload) => {
  //     console.log('received', payload);
  //   });
  // }, []);
  return (
    <>
      <Global />
      <Router>
        <Route exact path={'/'} render={() => <Home mode={mode} userId={userId} />} />
      </Router>
      <Footer mode={mode} setMode={setMode} />
    </>
  );
};

const Global = createGlobalStyle`
  @font-face {
    font-family: 'DSEG7MM';  
    src: local('DSEG7MM'),url(${DSGL7MM}) format('woff2');
    font-weight: 300;
    font-style: normal;
  }
  html, body {
    width: 100%;
    height: 100%;
  }
  
  body {
    font-family: 'DSEG7MM','Varela',
      "Helvetica Neue",
      Arial,
      "Hiragino Kaku Gothic ProN",
      "Hiragino Sans",
      Meiryo,
      sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${baseGradient};
    color: ${darkGray}
  }
  
  #root {
    background: ${base};
    ${neumorphicShadow}
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    width: 300px;
      height: 600px;
      min-height: 530px;
  }
  
  @media(max-height: 599px)  {
    #root {
      height: 100%;
    }
  }
  
`;

export default App;
