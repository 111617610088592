import React from 'react';
import styled from 'styled-components/macro';

type Props = {};

const Component: React.FC<Props> = (props) => {
  return (
    <Container {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75.11 97.72" fill="none">
      <line className="cls-1" x1="50.45" y1="64.73" x2="38.65" y2="85.17" />
      <line className="cls-1" x1="5.56" y1="66.07" x2="11.46" y2="55.85" />
      <path className="cls-1" d="M38.65,85.17A19.1,19.1,0,0,1,5.56,66.07" />
      <path className="cls-1" d="M43.46,38.63a19.12,19.12,0,0,1,7,26.1" />
      <line className="cls-1" x1="69.54" y1="31.65" x2="63.64" y2="41.88" />
      <line className="cls-1" x1="24.66" y1="33" x2="36.46" y2="12.55" />
      <path className="cls-1" d="M31.65,59.09a19.09,19.09,0,0,1-7-26.09" />
      <path className="cls-1" d="M36.46,12.55a19.1,19.1,0,0,1,33.08,19.1" />
    </Container>
  );
};

const Container = styled.svg`
  stroke: currentColor;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 6px;
`;

export default Component;
