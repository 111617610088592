import { useState, useEffect, useMemo, Dispatch, useCallback, useRef, useLayoutEffect, MutableRefObject } from 'react';
import NoSleep from 'nosleep.js';
import axios from 'axios';

type TickerProps = {
  bpm: number;
  tick: number;
  beat: number;
  bar: number;
};

// const initAt = Date.now();
// const untilInit = performance.now();
// const getNow = (): number => initAt + (performance.now() - untilInit);
// @ts-ignore
const AudioContext = window.AudioContext || window.webkitAudioContext;
const ctx = new AudioContext();
const noSleep = new NoSleep();

// unlock ios
// const initCtx = () => {
//   document.removeEventListener('click', initCtx);
//   const node = ctx.createBufferSource();
//   node.buffer = ctx.createBuffer(1, 1, 22050);
//   node.start(0);
// };
// document.addEventListener('click', initCtx);

export const useClick = (): [
  () => void,
  () => void,
  number,
  Dispatch<number>,
  number,
  Dispatch<number>,
  number,
  Dispatch<number>,
  number,
  Dispatch<number>,
  number,
  Dispatch<number>,
  number,
  number,
  number,
  number,
  MutableRefObject<number>,
  () => void,
  boolean
] => {
  const [startedTs, setStartedTs] = useState<number>(0);
  const [bpm, setBpm] = useState<number>(120);
  const [beat, setBeat] = useState<number>(4);
  const [bar, setBar] = useState<number>(8);
  const [tick, setTick] = useState<number>(2);
  const [currentBar, setCurrentBar] = useState<number>(0);
  const [currentBeat, setCurrentBeat] = useState<number>(0);
  const [currentTick, setCurrentTick] = useState<number>(0);
  const [beatProgress, setBeatProgress] = useState<number>(0);
  const [isInit, init] = useState<boolean>(false);
  // const [tickerCtx, setTickerCtx] = useState<TickerProps>({ bpm: 120, tick: 4, beat: 4, bar: 4 });

  const scheduleRef = useRef<number>(0);
  const intervalRef = useRef<number>(0);
  const startedRef = useRef<number>(0);
  const previousBar = useRef<number>(0);
  const previousBeat = useRef<number>(0);
  const previousTick = useRef<number>(0);
  const volumeRef = useRef<number>(0);
  const countQueue = useRef<{ bar: number; beat: number; tick: number; time: number }[]>([]);
  const timeDiff = useRef<number>(0);
  const initPerformanceTs = useRef<number>(0);
  const baseTime = useRef<number>(0);

  const msPerBar = useMemo(() => {
    return (60 / bpm) * beat * 1000;
  }, [bpm, beat]);
  const msPerBeat = useMemo(() => {
    return (60 / bpm) * 1000;
  }, [bpm]);
  const msPerTick = useMemo(() => {
    return (60 / bpm / tick) * 1000;
  }, [bpm, tick]);
  // const msPerBeat = secondsPerBeat * 1000;
  // const msPerTick = secondsPerTick * 1000;
  // const msPerBar = secondsPerBar * 1000;
  const getFixedNow = (): number => baseTime.current + (performance.now() - initPerformanceTs.current);

  useEffect(() => {
    const fn = async () => {
      initPerformanceTs.current = performance.now();
      const res = await axios.get('https://ntp-a1.nict.go.jp/cgi-bin/json');
      const receive = performance.now();
      baseTime.current = res.data.st * 10 ** 3 - (receive - initPerformanceTs.current) / 2;
    };
    fn();
  }, []);

  const initClick = () => {
    noSleep.enable();
    const node = ctx.createBufferSource();
    node.buffer = ctx.createBuffer(1, 1, 22050);
    node.start(0);
    init(true);
  };

  const startTick = () => {
    // scheduleRef.current = ctx.currentTime + 0.1;
    // intervalRef.current = setInterval(() => {
    //   scheduleNextBeat();
    // }, 25);
    initClick();
    const osc = ctx.createOscillator();
    const gainNode = ctx.createGain();
    gainNode.gain.value = 0;
    osc.connect(gainNode);
    gainNode.connect(ctx.destination);
    osc.frequency.value = 1760.0;
    osc.start(ctx.currentTime);
    gainNode.gain.setTargetAtTime(volumeRef.current, ctx.currentTime, 0.001);
    gainNode.gain.setTargetAtTime(0, ctx.currentTime + 0.05, 0.005);
    osc.stop(ctx.currentTime + 0.2);
    setStartedTs(getFixedNow());
  };

  const nextBar = () => (previousBar.current % bar) + 1;

  const nextBeat = () => {
    return (previousBeat.current % beat) + 1;
  };

  const nextTick = () => {
    return (previousTick.current % tick) + 1;
  };

  // const fetchTick = (startedTs: number) => {
  //   const msPerBeat = secondsPerBeat * 1000;
  //   const currentBeat = Math.ceil(((getNow() - startedTs) / msPerBeat) % tickerCtx.beat);
  //   setCurrentBeat(currentBeat);
  //   const delay = (msPerBeat - ((getNow() - startedTs) % msPerBeat)) / 1000;
  //   scheduleRef.current = ctx.currentTime + delay;
  //   intervalRef.current = setInterval(() => {
  //     scheduleNextBeat();
  //   }, 25);
  // };

  const scheduleNextBeat = () => {
    if (scheduleRef.current < ctx.currentTime + 0.1) {
      countQueue.current.push({
        bar: nextTick() === 1 && nextBeat() === 1 ? nextBar() : previousBar.current,
        beat: nextTick() === 1 ? nextBeat() : previousBeat.current,
        tick: nextTick(),
        time: scheduleRef.current,
      });

      const osc = ctx.createOscillator();
      const gainNode = ctx.createGain();
      gainNode.gain.value = 0;

      osc.connect(gainNode);
      gainNode.connect(ctx.destination);
      osc.frequency.value = nextTick() === 1 ? (nextBeat() === 1 ? 1760.0 : 880.0) : 659.255;
      osc.start(scheduleRef.current);
      gainNode.gain.setTargetAtTime(volumeRef.current, scheduleRef.current, 0.001);
      gainNode.gain.setTargetAtTime(0, scheduleRef.current + 0.05, 0.005);
      osc.stop(scheduleRef.current + 0.2);
      const next = scheduleRef.current + msPerTick / 1000;
      if (nextTick() === 1) {
        if (nextBeat() === 1) {
          previousBar.current = nextBar();
        }
        previousBeat.current = nextBeat();
      }
      previousTick.current = nextTick();
      // setSchedule(next);
      scheduleRef.current = next;
    }
  };

  useEffect(() => {
    if (startedTs && isInit) {
      startedRef.current = startedTs;
      const currentBar = Math.ceil(((getFixedNow() - startedTs) / msPerBar) % bar);
      const currentBeat = Math.ceil(((getFixedNow() - startedTs) / msPerBeat) % beat);
      const currentTick = Math.ceil(((getFixedNow() - startedTs) / msPerTick) % tick);
      setCurrentBar(currentBar);
      setCurrentBeat(currentBeat);
      setCurrentTick(currentTick);
      previousBar.current = currentBar;
      previousBeat.current = currentBeat;
      previousTick.current = currentTick;
      const delay = (msPerTick - ((getFixedNow() - startedTs) % msPerTick)) / 1000;
      scheduleRef.current = ctx.currentTime + delay;
      scheduleNextBeat();
      intervalRef.current = setInterval(() => {
        scheduleNextBeat();
      }, 25);
    } else {
      scheduleRef.current = 0;
      previousBar.current = 0;
      previousBeat.current = 0;
      previousTick.current = 0;
      startedRef.current = 0;
      clearInterval(intervalRef.current);
    }
    return () => clearInterval(intervalRef.current);
  }, [startedTs, bpm, bar, beat, tick, isInit]);

  const stopTick = () => {
    setStartedTs(0);
  };

  // useEffect(() => {
  //   intervalID && clearInterval(intervalID);
  //   if (scheduledTick) {
  //     // calculate next tick
  //     setIntervalID(
  //       setInterval(() => {
  //         scheduleNextBeat();
  //       }, 25)
  //     );
  //   }
  // }, [scheduledTick]);
  const frameRef = useRef<number>(0);
  const animate = (time: number) => {
    if (startedRef.current) {
      setBeatProgress(Math.round((((getFixedNow() - startedRef.current) % msPerBeat) / msPerBeat) * 10 ** 2) / 10 ** 2);
    }
    if (countQueue.current.length && countQueue.current[0].time < ctx.currentTime) {
      setCurrentBar(countQueue.current[0].bar);
      setCurrentBeat(countQueue.current[0].beat);
      setCurrentTick(countQueue.current[0].tick);
      countQueue.current.shift();
    }

    frameRef.current = requestAnimationFrame(animate);
  };

  useLayoutEffect(() => {
    frameRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(frameRef.current);
  }, [bpm]);

  return [
    startTick,
    stopTick,
    startedTs,
    setStartedTs,
    bpm,
    setBpm,
    beat,
    setBeat,
    bar,
    setBar,
    tick,
    setTick,
    currentBeat,
    currentBar,
    currentTick,
    beatProgress,
    volumeRef,
    initClick,
    isInit,
  ];
};
