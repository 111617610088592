import React from 'react';
import styled from 'styled-components/macro';

type Props = {};

const Component: React.FC<Props> = (props) => {
  return (
    <Container {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92.6 106" fill="none">
      <polyline className="cls-1" points="3 17.59 3 103 89.6 53 3 3" />
    </Container>
  );
};

const Container = styled.svg`
  stroke: currentColor;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 6px;
`;

export default Component;
