import React from 'react';
import styled, { css } from 'styled-components/macro';
import { darkGray, gray, lightGray, primary } from 'constants/color';

type Props = {
  ticks: number;
  beatProgress: number;
};

const Component: React.FC<Props> = (props) => {
  return (
    <Container {...props}>
      {Array.from({ length: props.ticks }, (_, i) => {
        return <Tick key={i} active={props.beatProgress !== 0 && i === Math.floor(props.beatProgress * props.ticks)} />;
      })}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Tick = styled.div<{ active: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${({ active }) => (active ? primary : gray)};
  ${({ active }) =>
    active &&
    css`
      transform: scale(1.5);
    `}
`;

export default Component;
