import React from 'react';
import styled from 'styled-components/macro';

type Props = {};

const Component: React.FC<Props> = (props) => {
  return <Container {...props}>ysysy</Container>;
};

const Container = styled.div``;

export default Component;
