import React, { useEffect, useRef } from 'react';
import QRCode from 'qrcode';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components/macro';

import { neumorphicConcarveShadow, neumorphicShallowShadowWithConcarve } from '../constants/mixin';
import { darkGray, gray, lightGray } from '../constants/color';

type Props = {
  url: string;
};

const Component: React.FC<Props> = (props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (canvasRef.current) {
      QRCode.toCanvas(
        canvasRef.current,
        props.url,
        { scale: 3, color: { dark: darkGray, light: lightGray } },
        (error) => {
          if (error) console.log(error);
        }
      );
    }
  }, [canvasRef, props.url]);
  return (
    <Container {...props}>
      <Label>Your sync URL</Label>
      <QR
        onClick={() => {
          navigator.clipboard.writeText(props.url).then(() => {
            toast('📋 Sync URL copied !');
          });
        }}
        ref={canvasRef}
      />
      <ExtLabel>( Tap to copy the URL )</ExtLabel>
      <ToastContainer autoClose={3000} position={'bottom-center'} pauseOnHover={false} hideProgressBar />
      {/*<Link ref={inputRef} type={'text'} readOnly value={props.url} />*/}
    </Container>
  );
};

const Container = styled.div`
  font-family: 'Valera';
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin: 0 16px;
  width: 100%;
`;

const Label = styled.div`
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-content: center;
`;

const ExtLabel = styled(Label)`
  color: ${gray};
  font-size: 12px;
`;

const Link = styled.input`
  border-radius: 32px;
  font-family: 'Valera';
  width: 100%;
  padding: 8px 16px;
  border: none;
  background: inherit;
  color: currentColor;
  font-size: 14px;
  ${neumorphicConcarveShadow}
`;
const QR = styled.canvas`
  align-self: center;
`;

export default Component;
