import React, { MutableRefObject, useState } from 'react';
import styled from 'styled-components/macro';
import { faVolumeMute, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { darkGray, gray } from '../constants/color';

type Props = {
  volumeRef: MutableRefObject<number>;
};

const Component: React.FC<Props> = (props) => {
  const [isActive, toggleActive] = useState<boolean>(false);
  return (
    <Container
      {...props}
      onClick={() => {
        toggleActive((prev) => {
          props.volumeRef.current = prev ? 0 : 0.3;
          return !prev;
        });
      }}
    >
      <FontAwesomeIcon icon={isActive ? faVolumeUp : faVolumeMute} size={'2x'} color={isActive ? darkGray : gray} />
    </Container>
  );
};

const Container = styled.button`
  border: none;
  padding: 0;
  cursor: pointer;
  background-color: inherit;
`;

export default Component;
