import { css } from 'styled-components/macro';
import { lighten, darken } from 'polished';

export const lightGray = '#F2F2F2';
export const lightGrayLightShadow = lighten(0.1, lightGray);
export const lightGrayDarkShadow = darken(0.18, lightGray);
export const gray = '#BFBFBF';
export const darkGray = '#585859';
export const black = '#0D0D0D';
export const yellowGreen = '#C2C200';

export const base = lightGray;
export const primary = yellowGreen;
export const primaryLightShadow = lighten(0.2, primary);
export const primaryDarkShadow = darken(0.18, primary);
export const primaryGlowed = lighten(0.2, primary);
export const baseGradient = css`
  linear-gradient(114deg, ${lightGray}, 70%, ${gray});
`;
