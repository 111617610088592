import React from 'react';
import styled from 'styled-components/macro';
import { neumorphicShadow, neumorphicConcarveShadow, neumorphicShallowShadow } from 'constants/mixin';
import { darkGray, gray } from '../constants/color';

type Props = {
  bpm: number;
  setBpm: React.Dispatch<number>;
  min?: number;
  max?: number;
  isActive: boolean;
};

const Component: React.FC<Props> = (props) => {
  return (
    <Container {...props}>
      <Input
        type={'number'}
        value={props.bpm}
        min={props.min || 20}
        max={props.max || 320}
        onChange={(e) => props.setBpm(parseInt(e.target.value, 10))}
        readOnly={props.isActive}
      />
    </Container>
  );
};

const Container = styled.div`
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Input = styled.input`
  width: 100%;
  border-radius: 8px;
  letter-spacing: 0.7px;

  text-align: center;
  font: inherit;
  font-size: 32px;
  background: inherit;
  border: none;
  color: ${darkGray};
`;

export default Component;
