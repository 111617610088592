import React from 'react';
import styled, { css } from 'styled-components/macro';
import { darkGray, gray } from 'constants/color';

type Props = {
  value: number;
  min?: number;
  max?: number;
  set: React.Dispatch<number>;
  isActive: boolean;
};

const Component: React.FC<Props> = (props) => {
  return (
    <Container
      type={'number'}
      value={props.value}
      min={props.min || 1}
      max={props.max || 999}
      readOnly={props.isActive}
      onChange={(e) => props.set(parseInt(e.target.value, 10))}
      {...props}
    />
  );
};

const Container = styled.input`
  width: 100%;
  font: inherit;
  background: inherit;
  border: none;
  color: ${darkGray};
`;

export default Component;
