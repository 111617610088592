import React from 'react';
import styled, { css } from 'styled-components/macro';

import ModeButtom from 'components/modeButton';
import Metronome from 'components/icons/metronome';
import Chain from 'components/icons/chain';
import { primary, primaryGlowed } from 'constants/color';
import { ModeType } from 'constants/type';

type Props = {
  setMode: React.Dispatch<ModeType>;
  mode?: ModeType;
};

const Component: React.FC<Props> = (props) => {
  return (
    <Container {...props}>
      <ExtModeButton onClick={() => props.setMode('home')} active={props.mode === 'home'}>
        <ExtMetronome active={props.mode === 'home'} />
      </ExtModeButton>
      <ExtModeButton onClick={() => props.setMode('sync')} active={props.mode === 'sync'}>
        <ExtChain active={props.mode === 'sync'} />
      </ExtModeButton>
    </Container>
  );
};

const Container = styled.div`
  height: 64px;
  width: 100%;
  display: flex;
`;

const ExtMetronome = styled(Metronome)<{ active: boolean }>`
  width: 20px;
  height: 20px;
  ${(props) =>
    props.active &&
    css`
      stroke: ${primary};
      filter: drop-shadow(0 0 2px ${primaryGlowed});
    `}
`;

const ExtChain = styled(Chain)<{ active: boolean }>`
  width: 24px;
  height: 24px;
  ${(props) =>
    props.active &&
    css`
      stroke: ${primary};
      filter: drop-shadow(0 0 2px ${primaryGlowed});
    `}
`;

const ExtModeButton = styled(ModeButtom)`
  flex: 1;
  height: 100%;
  max-width: 25%;
  margin: auto;
`;

export default Component;
