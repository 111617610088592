import firebase from 'firebase/app';
import 'firebase/messaging';
import 'firebase/auth';
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyCsAhGh1b2UIjvFLqFn11ZVcqmHGRGx8t8',
  authDomain: 'r3it4l.firebaseapp.com',
  databaseURL: 'https://r3it4l.firebaseio.com',
  projectId: 'r3it4l',
  storageBucket: 'r3it4l.appspot.com',
  messagingSenderId: '496294037609',
  appId: '1:496294037609:web:35aed9411d0d665276baf8',
  measurementId: 'G-E3JNFE2G57',
};
// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
}

export default firebase;
