import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { darkGray, gray, lightGray, primary } from 'constants/color';
import { neumorphicShallowShadowWithInset, neumorphicShallowShadowWithConcarve } from 'constants/mixin';

type Props = {
  disable?: boolean;
  onTurnOn: () => void;
  onTurnOff: () => void;
};

const Component: React.FC<Props> = ({ disable, onTurnOff, onTurnOn, ...props }) => {
  const [isActive, setActivation] = useState(false);
  return (
    <Container
      onClick={() => {
        if (disable) return;
        if (isActive) {
          onTurnOff();
        } else {
          onTurnOn();
        }
        setActivation((prev) => !prev);
      }}
      {...props}
      isActive={isActive}
    >
      {props.children}
    </Container>
  );
};

const Container = styled.button<{ isActive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: inherit;
  color: currentColor;
  border: 2px solid ${lightGray};
  ${neumorphicShallowShadowWithInset}
  ${(props) => {
    if (props.isActive) {
      return css`
        color: ${primary};
        ${neumorphicShallowShadowWithConcarve}
      `;
    }
  }}
  :focus {
    outline: none;
  }
`;

export default Component;
