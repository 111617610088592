import React from 'react';
import styled from 'styled-components/macro';

type Props = {};

const Component: React.FC<Props> = (props) => {
  return (
    <Container {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 106 92.6" fill="none">
      <polyline className="cls-1" points="70.7 33.66 53 3 3 89.6 38.41 89.6" />
      <polyline className="cls-1" points="88.68 27.8 53 89.6 103 89.6 85.16 58.7" />
    </Container>
  );
};

const Container = styled.svg`
  stroke: currentColor;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 6px;
`;

export default Component;
