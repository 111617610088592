import React from 'react';
import styled from 'styled-components/macro';
import { darkGray, gray } from '../constants/color';

type Props = {
  denominator: number;
  current: number;
  withOrder?: boolean;
};

const Component: React.FC<Props> = (props) => (
  <Container {...props}>
    {Array.from({ length: props.denominator }, (_, i) => {
      const order = i + 1;
      return (
        <Node key={i} withOrder={!!props.withOrder} order={order} active={props.current}>
          {props.withOrder && order}
        </Node>
      );
    })}
  </Container>
);

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(26px, 1fr));
  grid-gap: 2px;
`;

const Node = styled.div<{ withOrder: boolean; order: number; active: number }>`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => (props.withOrder ? 20 : 4)}px;
  background: ${({ order, active }) => (order <= active ? darkGray : gray)};
  //:not(:first-of-type) {
  //  margin-left: 2px;
  //}
  color: ${gray};
`;

export default Component;
