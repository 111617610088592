import React from 'react';
import styled from 'styled-components/macro';

import OperationBox from 'components/operationBox';
import ShareLink from 'components/shareLink';
import { ModeType } from 'constants/type';

type Props = {
  onPlay: () => void;
  onStop: () => void;
  mode: ModeType;
  syncID?: string;
  isSlave?: boolean;
  rejected?: boolean;
  isInit?: boolean;
  onInit: () => void;
};

const hostname = process.env.REACT_APP_HOSTNAME;

const Component: React.FC<Props> = ({ onStop, onPlay, ...props }) => {
  return (
    <Container {...props}>
      {props.mode === 'home' ? (
        <OperationBox
          onPlay={onPlay}
          onStop={onStop}
          isSlave={props.isSlave}
          rejected={props.rejected}
          isInit={props.isInit}
          onInit={props.onInit}
        />
      ) : (
        <ShareLink url={`${hostname}?hostID=${props.syncID}`} />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export default Component;
