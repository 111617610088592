import React from 'react';
import styled, { css } from 'styled-components/macro';

import { primary, darkGray } from 'constants/color';

type Props = {
  active?: boolean;
  onClick: () => void;
};

const Component: React.FC<Props> = (props) => {
  return (
    <Container onClick={props.onClick} {...props}>
      {props.children}
    </Container>
  );
};

const Container = styled.button<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: inherit;
  border: none;
  padding-bottom: 2px;
  color: ${darkGray};
  ${({ active }) =>
    active &&
    css`
      border-bottom: 2px solid ${primary};
      padding-bottom: 0;
    `};
  :focus {
    outline: none;
  }
`;

export default Component;
