import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'react-use';
import io from 'socket.io-client';
import styled from 'styled-components/macro';
import BpmMeter from 'components/bpmMeter';
import Indicator from 'components/indicator';
import ProgressBar from 'components/progressBar';
import Volume from 'components/volume';

import Share from 'pages/share';
import Interface from 'components/interface';
import TickerBox from 'components/tickerBox';
import { darkGray, gray, primary } from 'constants/color';

import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { neumorphicConcarveShadow } from 'constants/mixin';
import { useClick } from 'hooks/useClick';
import { ModeType } from 'constants/type';

type Props = {
  mode: ModeType;
  userId: string;
};

type Message = {
  startedTs: number;
  bpm: number;
  beat: number;
  bar: number;
  tick: number;
};

const Component: React.FC<Props> = (props) => {
  const location = useLocation();
  const urlQuery = new URLSearchParams(location.search);
  const hostID = urlQuery.get('hostID');
  const [socket] = useState<SocketIOClient.Socket>(() => io('https://tick3r.herokuapp.com/'));
  const [isConnected, toggleConnected] = useState<boolean>(false);
  const [memberAmount, setMemberAmount] = useState<number>(0);
  const [isRejected, toggleRejected] = useState<boolean>(false);

  const [
    startTick,
    stopTick,
    startedTs,
    setStartedTs,
    bpm,
    setBpm,
    beat,
    setBeat,
    bar,
    setBar,
    tick,
    setTick,
    currentBeat,
    currentBar,
    currentTick,
    beatProgress,
    volumeRef,
    initClick,
    isInit,
  ] = useClick();

  const [isJoined, joinSocket] = useState<boolean>(false);

  // useEffect(() => {
  //   document.addEventListener('click', initClick);
  // }, []);

  useEffect(() => {
    socket.on('connect', () => {
      toggleConnected(true);
    });
    socket.on('memberLimit', () => {
      socket.disconnect();
      toggleConnected(false);
      toggleRejected(true);
    });
    socket.on('tickerMembers', (member: number) => {
      setMemberAmount(member);
    });
    socket.on('fetch', ({ startedTs, bpm, beat, bar, tick }: Message) => {
      if (hostID) {
        setStartedTs(startedTs);
        setBeat(beat);
        setBar(bar);
        setBpm(bpm);
        setTick(tick);
      }
    });
    const removeSocket = () => socket.close();
    window.addEventListener('beforeunload', removeSocket);
    return () => {
      window.removeEventListener('beforeunload', removeSocket);
    };
  }, [socket]);

  useEffect(() => {
    if (isJoined) return;
    if (socket && hostID) {
      socket.emit('joinTicker', hostID);
      joinSocket(true);
    } else if (socket && props.userId) {
      socket.emit('joinHost', props.userId);
      joinSocket(true);
    }
  }, [socket, hostID, props.userId]);

  useEffect(() => {
    socket?.on('joinSomeone', (slaveID: string) => {
      if (!hostID) {
        socket?.emit('sendTickerDirect', slaveID, { startedTs, bpm, beat, bar, tick });
      }
    });
    return () => {
      socket?.off('joinSomeone');
    };
  }, [socket, startedTs, bpm, beat, bar, tick]);

  useDebounce(
    () => {
      if (!hostID && props.userId) {
        socket?.emit('sendTicker', props.userId, { startedTs, bpm, beat, bar, tick });
      }
    },
    100,
    [socket, props.userId, startedTs, bpm, beat, bar, tick]
  );

  return (
    <>
      <Container>
        <Logo>
          <a href={'/'}>tick3r</a>
          <InfoLink
            href={
              'https://gitter.im/0r3it4l-tick3r/community?utm_source=share-link&utm_medium=link&utm_campaign=share-link'
            }
            target={'_blank'}
          >
            <FontAwesomeIcon icon={faQuestionCircle} color={gray} />
          </InfoLink>
        </Logo>
        <VolumeBox>
          <Volume volumeRef={volumeRef} />
        </VolumeBox>
        <Display>
          <ExtTickLabel>Tick</ExtTickLabel>
          <ExtTickIndicator value={tick} set={setTick} max={6} isActive={!!hostID} />
          <ExtBeatLabel>Beat</ExtBeatLabel>
          <ExtBeatIndicator value={beat} set={setBeat} max={32} isActive={!!hostID} />
          <ExtBarLabel>Bar</ExtBarLabel>
          <ExtBarIndicator value={bar} set={setBar} max={16} isActive={!!hostID} />
          <ExtMeterLabel>Tempo</ExtMeterLabel>
          <ExtBpmMeter bpm={bpm} setBpm={setBpm} isActive={!!hostID} />
          <ProgressMeter>
            <ProgressBar withOrder denominator={bar} current={currentBar} />
            <ProgressBar denominator={beat} current={currentBeat} />
          </ProgressMeter>
        </Display>
        <ExtTickerBox ticks={4} beatProgress={beatProgress} />
        <ExtInterface
          mode={props.mode}
          syncID={props.userId}
          isSlave={!!hostID}
          isInit={isInit}
          onInit={initClick}
          rejected={isRejected}
          onPlay={() => {
            startTick();
          }}
          onStop={() => {
            stopTick();
          }}
        />
      </Container>
    </>
  );
};

const Container = styled.div`
  flex: 1;
  display: grid;
  grid-template:
    'logo logo volume' 56px
    'display display display'
    '. ticker .' 40px
    'interface interface interface' 1fr
    / 1fr 1fr 1fr;
  margin: 8px 8px 0 8px;
  padding: 8px;
  border-radius: 16px 16px 8px 8px;
  ${neumorphicConcarveShadow}
`;

const Display = styled.div`
  grid-area: display;
  display: grid;
  border-radius: 8px;
  margin: 8px;
  padding: 8px;
  max-height: 240px;
  grid-gap: 0 8px;
  grid-template:
    '. . tickLabel beatLabel barLabel' 24px
    '. . tickIndicator beatIndicator barIndicator' 24px
    '. meterLabel meterLabel . .' 1fr
    '. meter meter meter .'
    '. . . . .' 1fr
    'progress progress progress progress progress' 48px
    / 1fr 1fr 1fr 1fr 1fr;

  overflow: hidden;
  ${neumorphicConcarveShadow}
`;

const Logo = styled.div`
  grid-area: logo;
  margin-top: 4px;
  margin-left: 8px;
  font-family: 'Fredoka One';
  font-size: 36px;
  a {
    color: ${primary};
    text-decoration: none;
  }
`;

const VolumeBox = styled.div`
  grid-area: volume;
  display: flex;
  justify-content: flex-end;
  padding-right: 8px;
  padding-top: 4px;
  align-items: center;
`;

const InfoLink = styled.a`
  font-size: 16px;
  margin-left: 8px;
`;

const ProgressMeter = styled.div`
  grid-area: progress;
  align-self: end;
  & > * {
    :not(:last-child) {
      margin-bottom: 4px;
    }
  }
`;

const ExtIndicator = styled(Indicator)`
  justify-self: end;
`;

const ExtTickIndicator = styled(ExtIndicator)`
  grid-area: tickIndicator;
`;

const ExtBeatIndicator = styled(ExtIndicator)`
  grid-area: beatIndicator;
`;

const ExtBarIndicator = styled(ExtIndicator)`
  grid-area: barIndicator;
`;

const Label = styled.div`
  font-family: 'Varela';
  font-size: 14px;
  align-self: end;
`;

const ExtMeterLabel = styled(Label)`
  padding-right: 8px;
  grid-area: meterLabel;
`;
const ExtTickLabel = styled(Label)`
  grid-area: tickLabel;
`;

const ExtBeatLabel = styled(Label)`
  grid-area: beatLabel;
`;

const ExtBarLabel = styled(Label)`
  grid-area: barLabel;
`;

const ExtInterface = styled(Interface)`
  grid-area: interface;
`;

const ExtShare = styled(Share)`
  grid-area: interface;
`;

const ExtBpmMeter = styled(BpmMeter)`
  grid-area: meter;
  justify-self: center;
  align-self: center;
  width: 100%;
  height: 100%;
`;

const ExtTickerBox = styled(TickerBox)`
  width: 100%;
  margin: 16px;
  grid-area: ticker;
  justify-self: center;
  align-self: center;
`;

export default Component;
