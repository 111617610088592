import { css } from 'styled-components/macro';
import { lightGrayDarkShadow, lightGrayLightShadow } from './color';

export const neumorphicBorder = css`
  border-radius: 24px;
`;

export const neumorphicShadow = css`
  box-shadow: 17px 17px 32px ${lightGrayDarkShadow}, -13px -13px 24px ${lightGrayLightShadow};
`;

export const neumorphicShallowShadow = css`
  box-shadow: 5px 5px 8px ${lightGrayDarkShadow}, -5px -5px 8px ${lightGrayLightShadow};
`;

export const neumorphicShallowShadowWithInset = css`
  box-shadow: 5px 5px 8px ${lightGrayDarkShadow}, -5px -5px 8px ${lightGrayLightShadow},
    inset 3px 3px 8px ${lightGrayLightShadow}, inset -3px -3px 8px ${lightGrayDarkShadow};
`;

export const neumorphicShallowShadowWithConcarve = css`
  box-shadow: 5px 5px 8px ${lightGrayDarkShadow}, -5px -5px 8px ${lightGrayLightShadow},
    inset 3px 3px 8px ${lightGrayDarkShadow}, inset -3px -3px 8px ${lightGrayLightShadow};
`;

export const neumorphicConcarveBorder = css`
  border-radius: 16px;
`;

export const neumorphicConcarveShadow = css`
  box-shadow: inset 3px 3px 8px ${lightGrayDarkShadow}, inset -3px -3px 8px ${lightGrayLightShadow};
`;
